<template>
  <div id="app" style="padding: 15px">
      <v-toolbar color="tertiary" dark>
        <v-toolbar-title>Slack</v-toolbar-title>
      </v-toolbar>
      <v-container fluid class="grey lighten-5">
        <p>Time Tracker is now usable in two different ways from Slack workspaces where it is installed.</p>
        <p>First, you can use /slash commands by typing /tt [action] where you replace [action] with the action you want.</p>
         <ul>
          <li><strong>/tt me</strong>: Retrieves your time off and the teams to which you belong.</li>
        </ul>
        <br>All of the following pull information for you and the people with whom you are associated (via teams or individually).
        <ul>
          <li><strong>/tt month</strong>: Displays time off for the current month.</li>
          <li><strong>/tt nmon</strong>: Displays time off for next month.</li>
          <li><strong>/tt week</strong>: Displays time off for the current week.</li>
          <li><strong>/tt nweek</strong>: Displays time off for the next week.</li>
          <li><strong>/tt busweek</strong>: Displays time off for the business week (M-F).</li>
          <li><strong>/tt tomorrow</strong>: Displays time off for tomorrow.</li>
          <li><strong>/tt today</strong>: Displays time off for today.</li>
        </ul>
        <br>
        <p>Second, you can use a global short-cut to enter your Time Off entry directly from Slack.  Click the lightning-bolt, type in a few letters for the app (Time Tracker in this case), and click Create time off.</p>
        <v-img
          alt="IBM TT Slack LB"
          class="shrink mx-5"
          contain
          src="/slack_lbolt.png"
          transition="scale-transition"
          width="260"
        />
        <br>
        <p>You will then be presented with a form to fill out, and when you click Submit, your entry will be created and normal notifications will occur per the settings of your team(s).</p>
        <v-img
          alt="IBM TT Slack Entry"
          class="shrink mx-5"
          contain
          src="/slack_entry.png"
          transition="scale-transition"
          width="518"
        />
        <br>
        <p>You can also edit any of your existing Time Tracker entries from Slack by selecting the <strong>Edit time off</strong> option and picking the desired one from the drop-down.</p>
      </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Help_Slack",
  data() {
    return {
      email: "",
      show_err: false
    };
  },
  computed: {},
  methods: {
    authAPI() {
      const path = "https://timetracker.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then(res => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href =
              "https://timetracker.mail.ibm.com/api/login";
          } else {
            this.email = res.data.email;
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    }
  },
  created() {
    this.authAPI();
  }
};
</script>
