<template>
  <div id="app" style="padding: 15px">
        <p>
          This page has migrated to https://gd-apps.mail.ibm.com/contacts
        </p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Signup",
  data() {
    return {
      email: "",
      showchecking: true,
      showdenied: false,
      permitsignup: -1,
      showsignup: false,
      signedup: "",
      show_err: false,
    };
  },
  computed: {},
  methods: {
    sendNotification() {
      const path = "https://timetracker.mail.ibm.com/api/contacts";

      axios
        .get(path)
        .then((res) => {
          let status = res.data.status;
          if (status == "success") {
            this.signedup =
              "Congratulations.  Your contacts are queued for export and will be sent to " +
              this.email;
          } else {
            // Something went wrong...
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    authAPI() {
      // Redirect to new site
      window.location.href = "https://gd-apps.mail.ibm.com/contacts";


      const path = "https://timetracker.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then((res) => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href = "https://timetracker.mail.ibm.com/api/login?ploc=contacts";
          } else {
            this.email = res.data.email;
            this.showchecking = false;
            this.permitsignup = 1;
            this.showsignup = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
  created() {
    this.authAPI();
  },
};
</script>
