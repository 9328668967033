import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Config from '../views/Config.vue'
import View from '../views/View.vue'
import Help from '../views/Help.vue'
import Contacts from '../components/Contacts.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/view/:comp',
    name: 'View',
    component: View
  },
  {
    path: '/config',
    name: 'Config',
    component: Config
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
