<template>
  <div id="app" style="padding: 15px">
    <v-overlay :value="doLoad">
      <v-progress-circular :size="50" indeterminate></v-progress-circular>
    </v-overlay>
    <v-card max-width="1000" class="mx-auto">
      <v-toolbar color="secondary" dark>
        <v-toolbar-title>Profile for {{ userinfo.email }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-avatar>
          <v-img :src="imgpic" :alt="userinfo.firstname" />
        </v-avatar>
      </v-toolbar>
      <v-container fluid class="grey lighten-5">
        <v-row dense>
          <v-col cols='5'>
            <v-text-field v-model="firstName" label="First Name"></v-text-field>
          </v-col>
          <v-col cols='5'>
            <v-text-field v-model="lastName" label="Last Name"></v-text-field>
          </v-col>
          <v-col cols='2'>
            <v-select
              v-model="avatar"
              :items="ibmgrav"
              label="Avatar"
            ></v-select
          >
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="grey lighten-5">
        <v-row dense>
          <v-col>
            <v-select
              v-model="holidays"
              :items="listholidays"
              label="Holidays"
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              v-model="vacadays"
              type="number"
              label="# of Vacation Days"
            ></v-text-field>
          </v-col>
          <v-col
            ><v-select
              v-model="doical"
              :items="yesno"
              label="Send iCal?"
            ></v-select
          ></v-col>
          <v-col>
            <v-select
              v-model="tzone"
              :items="time_zones"
              label="Select your time zone"
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            {{ totaldays }}
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea
              v-model="notifyindvs"
              label="Individuals"
              auto-grow
              rows="1"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea
              name="input-7-1"
              filled
              label="Teams you own"
              auto-grow
              rows="1"
              readonly
              :value="townerlist"
            ></v-textarea>
          </v-col>
          <v-col>
            <v-textarea
              name="input-7-1"
              filled
              label="Teams to which you belong"
              auto-grow
              rows="1"
              readonly
              :value="tmemberlist"
            ></v-textarea>
          </v-col>
          <v-col>
            <v-textarea
              name="input-7-1"
              filled
              label="Teams for which you are a guest"
              auto-grow
              rows="1"
              readonly
              :value="tguestlist"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-select
              v-model="notifyteams"
              :items="listteams"
              multiple
              label="Teams which you have joined"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="selpendteam"
              :items="pendingteams"
              label="Teams with pending invitations"
            ></v-select>
            <v-btn
              v-show="typeof selpendteam !== 'undefined' && selpendteam.length > 0"
              text
              color="primary lighten-2"
              small
              v-on:click="respondTeam('yes')"
              >Join Selected Team</v-btn
            ><v-btn
              v-show="typeof selpendteam !== 'undefined' && selpendteam.length > 0"
              text
              color="primary lighten-2"
              small
              v-on:click="respondTeam('no')"
              >Decline Team Invitation</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="grey lighten-5">
        <v-row dense>
          <v-col>
            <v-textarea
              v-model="schedulelists"
              label="PagerDuty Schedules"
              auto-grow
              rows="1"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn color="primary lighten-1" text @click="updateUser()"
          >Update</v-btn
        >
        <v-spacer></v-spacer>
        <v-card-text color="primary lighten-1">{{ savemsg }}</v-card-text>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Profile",
  data() {
    return {
      show_err: false,
      savemsg: "",
      doLoad: false,
      userinfo: "",
      firstName: "",
      lastName: "",
      avatar: "",
      imgpic: "",
      schedulelists: "",
      notifyindvs: "",
      notifyteams: [],
      listteams: [],
      selpendteam: [],
      pendingteams: [],
      townerlist: "",
      tmemberlist: "",
      tguestlist: "",
      holidays: [],
      listholidays: [],
      listentries: [],
      vacadays: "",
      pchdays: "?",
      yesno: ["yes", "no"],
      ibmgrav: ["IBM", "Gravatar"],
      doical: "yes",
      tzone: "",
      time_zones: [],
    };
  },
  computed: {
    totaldays: function () {
      if (this.listentries.length < 1) {
        return "No entries.";
      }
      let totvacs = 0;
      let totpcs = 0;
      let totother = 0;
      for (let x = 0; x < this.listentries.length; x++) {
        let tmpdays = parseFloat(this.listentries[x]["compdays"]);
        if (this.listentries[x]["etype"] == "Vacation") {
          totvacs = totvacs + tmpdays;
        } else {
          if (this.listentries[x]["etype"] == "PC Day") {
            totpcs = totpcs + tmpdays;
          } else {
            totother = totother + tmpdays;
          }
        }
      }
      return (
        " Vacation: " +
        totvacs +
        " out of " +
        this.vacadays +
        " | PC Day: " +
        totpcs +
        " out of " +
        this.pchdays +
        " | Other: " +
        totother
      );
    },
  },
  methods: {
    getUserInfo() {
      const path = "https://timetracker.mail.ibm.com/api/userinfo";
      this.doLoad = true;

      axios
        .get(path)
        .then((res) => {
          this.userinfo = res.data.userinfo;
          this.firstName = this.userinfo.firstName;
          this.lastName = this.userinfo.lastName;
          this.imgpic = res.data.imgpic;
          this.schedulelists = this.userinfo.schedulelists;
          this.notifyindvs = this.userinfo.notifyindvs;
          this.notifyteams = this.userinfo.notifyteams;
          this.listteams = res.data.listteams;
          this.pendingteams = res.data.pendingteams;
          this.townerlist = res.data.townerlist;
          this.tmemberlist = res.data.tmemberlist;
          this.tguestlist = res.data.tguestlist;
          this.selpendteam = this.pendingteams[0];
          this.holidays = this.userinfo.holidays;
          this.listholidays = res.data.listholidays;
          this.vacadays = this.userinfo.vacadays;
          this.pchdays = res.data.pchdays;
          this.listentries = res.data.listentries;
          const doical_num = this.userinfo.doical;
          this.tzone = this.userinfo.tzone;
          this.doical = "no"
          if (doical_num == 1) {
            this.doical = "yes"
          }
          this.avatar = this.userinfo.avatar;
          this.time_zones = res.data.tzs;
          this.doLoad = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          this.doLoad = false;
        });
    },
    updateUser() {
      const path = "https://timetracker.mail.ibm.com/api/updateuser";
      this.doLoad = true;

      this.savemsg = "";
      let tmparr_NI = [];
      if (this.notifyindvs.toString() != "") {
        tmparr_NI = this.notifyindvs
          .toString()
          .trim()
          .split(/[ ,;\t\n]+/);
      }
      let tmparr_P = [];
      if (this.schedulelists.toString() != "") {
        tmparr_P = this.schedulelists.trim().split(/[ ,;\t\n]+/);
      }
      let doical_num = 0
      if (this.doical == "yes") {
        doical_num = 1
      }
      let upentry = {
        firstName: this.firstName,
        lastName: this.lastName,
        schedulelists: tmparr_P,
        notifyindvs: tmparr_NI,
        notifyteams: this.notifyteams,
        holidays: this.holidays,
        vacadays: this.vacadays,
        doical: doical_num,
        tzone: this.tzone,
        avatar: this.avatar,
      };

      axios
        .post(path, upentry)
        .then((res) => {
          this.savemsg = res.data.savemsg;
          if (this.savemsg.startsWith("Updated ")) {
            this.show_err = false;
            this.getUserInfo();
          } else {
            this.show_err = true;
          }
          this.doLoad = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          this.doLoad = false;
        });
    },
    respondTeam(answer) {
      const path =
        "https://timetracker.mail.ibm.com/api/jointeam?tname=" +
        this.selpendteam + "&answer=" + answer;

      axios
        .get(path)
        .then((res) => {
          this.savemsg = res.data.savemsg;
          if (this.savemsg.startsWith("Joined ") || this.savemsg.startsWith("Declined ")) {
            this.show_err = false;
            this.getUserInfo();
          } else {
            this.show_err = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
  created() {
    this.getUserInfo();
  },
};
</script>
