<template>
  <div id="app" style="padding: 15px">
    <v-card max-width="1000" class="mx-auto">
      <v-toolbar color="tertiary" dark>
        <v-toolbar-title>Teams</v-toolbar-title>
      </v-toolbar>
      <v-container fluid class="grey lighten-5">
        <v-row dense>
          <v-col>
            <v-text-field v-model="teamName" label="Team Name"></v-text-field>
          </v-col>
          <v-col>
            <v-textarea v-model="teamOwners" label="Team Owners" auto-grow rows="1"></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea v-model="teamMembers" label="Team Members" auto-grow rows="1"></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea v-model="teamGuests" label="Guests" auto-grow rows="1"></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea v-model="pendingMembers" label="Members with pending invitations" readonly auto-grow rows="1"></v-textarea>
            <v-btn v-if="pendingMembers != ''" text color="primary lighten-2" small v-on:click="notifyPending()">Send Invitations</v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea v-model="slackchannels" spellcheck="false" label="Slack Channels (copy channel link from Slack or enter as workspace|channelid)" auto-grow rows="1"></v-textarea>
          </v-col>
        </v-row>
        <v-row v-show="slackchannels.length > 0" dense>
          <v-col>
            <span class="text-body-2">(Don't forget to invite TimeTracker to your channel if it is private (e.g., /invite @TimeTracker))</span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea v-model="schedulelists" spellcheck="false" label="PagerDuty Schedules" auto-grow rows="1"></v-textarea>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn v-if="uid==''" color="primary lighten-1" text @click="createTeam()">Create</v-btn>
        <v-btn v-if="uid!=''" color="primary lighten-1" text @click="updateTeam()">Update</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="uid!=''" text color="primary lighten-1" @click="deleteTeam()">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="uid!=''" color="primary lighten-1" text @click="clearTeam()">Clear</v-btn>
        <v-container v-if="savemsg!=''">
          <v-row>
            <v-col>
              <v-card-text color="primary lighten-1">{{ savemsg }}</v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <v-container mt-5>
      <v-data-table
        :headers="headers"
        :items="allteams"
        :items-per-page="15"
        class="elevation-1"
        @click:row="loadTeam"
      >
        <template v-slot:item.members="{ item }">{{ chopText(item.members) }}</template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
const maxtextlen = 300;

export default {
  name: "Teams",
  data() {
    return {
      email: "",
      show_err: false,
      savemsg: "",
      uid: "",
      teaminfo: "",
      teamName: "",
      teamOwners: "",
      teamMembers: "",
      teamGuests: "",
      slackchannels: "",
      schedulelists: "",
      pendingMembers: "",
      allteams: [],
      headers: [
        {
          text: "Team Name",
          align: "start",
          value: "name"
        },
        { text: "Team Owners", value: "owners" },
        { text: "Team Members", value: "members" }
      ]
    };
  },
  computed: {},
  methods: {
    loadTeam(value) {
      this.savemsg = "";
      this.uid = value.uid;
      this.teamName = value.name;
      this.teamOwners = value.owners;
      this.teamMembers = value.members;
      this.teamGuests = value.guests;
      this.slackchannels = value.slackchannels;
      this.schedulelists = value.schedulelists;
      this.pendingMembers = value.pendingmembers;
    },
    clearTeam() {
      this.savemsg = "";
      this.uid = "";
      this.teamName = "";
      this.teamOwners = "";
      this.teamMembers = "";
      this.teamGuests = "";
      this.slackchannels = "";
      this.schedulelists = "";
      this.pendingMembers = "";
    },
    chopText(textval) {
      let trunctext = textval.replace(/,/g, ", ").substring(0, maxtextlen + 1);
      const lastspace = trunctext.lastIndexOf(" ");
      if (lastspace < 2 || lastspace > maxtextlen) {
        return trunctext;
      }
      return trunctext.substring(0, lastspace).trim() + "...";
    },
    getTeams() {
      const path = "https://timetracker.mail.ibm.com/api/teams";

      axios
        .get(path)
        .then(res => {
          this.allteams = res.data.allteams;
          this.email = res.data.email;
          if (this.teamOwners == "") {
            this.teamOwners = this.email;
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getTeamInfo() {
      if (this.uid == "") {
        return;
      }
      const path =
        "https://timetracker.mail.ibm.com/api/singleteam?uid=" + this.uid;

      axios
        .get(path)
        .then(res => {
          this.teaminfo = res.data.teaminfo;
          this.teamName = this.teaminfo.name;
          this.teamOwners = this.teaminfo.owners;
          this.teamMembers = this.teaminfo.members;
          this.teamGuests = this.teaminfo.guests;
          this.slackchannels = this.teaminfo.slackchannels;
          this.schedulelists = this.teaminfo.schedulelists;
          this.pendingMembers = this.teaminfo.pendingmembers;
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    createTeam() {
      const path = "https://timetracker.mail.ibm.com/api/createteam";

      this.savemsg = "";
      const tmparr_O = this.teamOwners.trim().split(/[ ,;\t\n]+/);
      const tmparr_M = this.teamMembers.trim().split(/[ ,;\t\n]+/).sort();
      const tmparr_G = this.teamGuests.trim().split(/[ ,;\t\n]+/).sort();
      const tmparr_S = this.slackchannels.trim().split(/[ ,;\t\n]+/);
      const tmparr_P = this.schedulelists.trim().split(/[ ,;\t\n]+/);
      let upentry = {
        name: this.teamName,
        owners: tmparr_O,
        members: tmparr_M,
        guests: tmparr_G,
        slackchannels: tmparr_S,
        schedulelists: tmparr_P
      };

      axios
        .post(path, upentry)
        .then(res => {
          const holdmsg = res.data.savemsg;
          if (holdmsg.startsWith("Created ")) {
            this.show_err = false;
            this.teaminfo = res.data.teaminfo;
            this.loadTeam(this.teaminfo);
            this.getTeams();
            this.savemsg = holdmsg;
          } else {
            this.show_err = true;
            this.savemsg = holdmsg;
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    updateTeam() {
      const path = "https://timetracker.mail.ibm.com/api/updateteam";

      this.savemsg = "";
      const tmparr_O = this.teamOwners.trim().split(/[ ,;\t\n]+/);
      const tmparr_M = this.teamMembers.trim().split(/[ ,;\t\n]+/).sort();
      const tmparr_G = this.teamGuests.trim().split(/[ ,;\t\n]+/).sort();
      const tmparr_S = this.slackchannels.trim().split(/[ ,;\t\n]+/);
      const tmparr_P = this.schedulelists.trim().split(/[ ,;\t\n]+/);
      let upentry = {
        uid: this.uid,
        name: this.teamName,
        owners: tmparr_O,
        members: tmparr_M,
        guests: tmparr_G,
        slackchannels: tmparr_S,
        schedulelists: tmparr_P
      };

      axios
        .post(path, upentry)
        .then(res => {
          const holdmsg = res.data.savemsg;
          if (holdmsg.startsWith("Updated ")) {
            this.show_err = false;
            this.teaminfo = res.data.teaminfo;
            this.loadTeam(this.teaminfo);
            this.getTeams();
            this.savemsg = holdmsg;
          } else {
            this.show_err = true;
            this.savemsg = holdmsg;
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    deleteTeam() {
      if (this.uid == "") {
        return;
      }
      const path =
        "https://timetracker.mail.ibm.com/api/deleteteam?uid=" + this.uid;

      axios
        .get(path)
        .then(res => {
          const holdmsg = res.data.savemsg;
          if (holdmsg.startsWith("Deleted ")) {
            this.show_err = false;
            this.clearTeam();
            this.getTeams();
            this.savemsg = holdmsg;
          } else {
            this.show_err = true;
            this.savemsg = holdmsg;
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    notifyPending() {
      if (this.uid == "") {
        return;
      }
      const path =
        "https://timetracker.mail.ibm.com/api/notify?uid=" + this.uid;

      axios
        .get(path)
        .then(res => {
          const holdmsg = res.data.savemsg;
          if (holdmsg.startsWith("Notified ")) {
            this.show_err = false;
            this.savemsg = holdmsg;
          } else {
            this.show_err = true;
            this.savemsg = holdmsg;
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    authAPI() {
      const path = "https://timetracker.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then(res => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href =
              "https://timetracker.mail.ibm.com/api/login";
          } else {
            this.getTeams();
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    }
  },
  created() {
    this.authAPI();
  }
};
</script>
