<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="navdraw = !navdraw">
        <div class="d-flex align-center">
          <v-icon large color="white darken-1">mdi-menu</v-icon>
        </div>
      </v-app-bar-nav-icon>
      <v-img
        alt="IBM Time Tracker"
        class="shrink"
        contain
        min-width="100"
        src="/sitetitle-combo.png"
        height="50"
      />
      <v-spacer></v-spacer>
      <span>{{ timestamp }}</span>
    </v-app-bar>
    <v-navigation-drawer
      app
      v-model="navdraw"
      bottom
      offset-y
      temporary
      height="auto"
      color="grey lighten-5"
    >
      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>{{ item.title }}</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    timestamp: "",
    navdraw: false,
    menuItems: [
      { title: "Calendar", path: "/", icon: "mdi-chart-bar" },
      { title: "Out of Office", path: "/view/OoO", icon: "mdi-table-large" },
      { title: "Schedule", path: "/view/schedule", icon: "mdi-table-large" },
      { title: "Configuration", path: "/config", icon: "mdi-cog" },
      { title: "Help", path: "/help", icon: "mdi-help" },
    ],
  }),
  methods: {
    getDate() {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const today = new Date();
      const date =
        today.getFullYear() +
        " " +
        months[today.getMonth()] +
        " " +
        today.getDate();
      const dateTime = date; //+ " " + time;
      this.timestamp = dateTime;
    },
  },
  created() {
    this.getDate();
  },
};
</script>
