<template>
  <div id="app" style="padding: 15px">
      <v-toolbar color="tertiary" dark>
        <v-toolbar-title>Teams</v-toolbar-title>
      </v-toolbar>
      <v-container fluid class="grey lighten-5">
        <p>To create a new Time Tracker team, fill in the form and click Save.  You can separate multiple values with commas, semicolons, or new lines.</p>
        <v-img
          alt="IBM TT New Entry"
          class="shrink mx-5"
          contain
          src="/tt_team.png"
          transition="scale-transition"
          width="745"
        />
        <br>
        <p>To edit a Time Tracker team you own, scroll down and select the desired team from the list.  The team values will be filled in above so that you can edit them and then click Save when finished.</p>
        <p>You can delete a team you own by selecting it from the team list then clicking Delete.</p>
        <p>The Clear button will reset the form to blank values.</p>
        <v-subheader class="secondary--text text--lighten-3">Team Fields</v-subheader>
        <p>The <strong>Guests</strong> field allows you to share your team's Time Tracker entries with people who are not part of your team.<br>
        When you have been granted guest access to one or more teams, you can view those team's entries in your Calendar.  There is also a toggle switch to hide guest entries when you wish to view only entries for your teams.
        </p>
        <p>The <strong>Slack Channels</strong> field allows you to add Slack channels into which new Time Tracker entry notifications will be posted.<br>
        You must first add the Time Tracker application to your workspace, and once that is done, add the Time Tracker App to the desired channel.  If your channel is private, you need to invite TimeTracker to it (e.g., /invite @TimeTracker).<br>
        To add a channel, simply copy the URL link for the channel and paste it into the Slack Channels field.  When you click Save, the URL will automatically be converted into workspace|channelID format.<br>
        Time Tracker entries will only be posted for members of a team (not owners or guests).  Owners can of course add themselves as members of the team as well.
        </p>
        <p>The <strong>PagerDuty Schedules</strong> field allows you to add the PagerDuty SchedID (currently a 7 character alphanumeric string) of team members.<br>
        You can then view all team members with schedules in a single view by selecting the <strong>Schedule</strong> option available by clicking the main icon in the top left corner.
        </p> 
      </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Help_Teams",
  data() {
    return {
      email: "",
      show_err: false
    };
  },
  computed: {},
  methods: {
    authAPI() {
      const path = "https://timetracker.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then(res => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href =
              "https://timetracker.mail.ibm.com/api/login";
          } else {
            this.email = res.data.email;
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    }
  },
  created() {
    this.authAPI();
  }
};
</script>
