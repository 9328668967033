<template>
  <div id="app">
    <v-overlay :value="show_loading">
      <v-progress-circular size="64" indeterminate></v-progress-circular>
    </v-overlay>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="1">
          <v-select v-model="pickyear" :items="years" chips></v-select>
        </v-col>
        <v-col cols="1">
          <v-select v-model="pickmonth" :items="months" chips></v-select>
        </v-col>
        <v-col cols="1">
          <v-select v-model="pickday" :items="days" chips></v-select>
        </v-col>
        <v-col cols="1">
          <v-btn icon color="grey" x-small v-on:click="setDay('f')">
            <v-icon>mdi-arrow-left</v-icon> </v-btn
          >&nbsp;&nbsp;&nbsp;
          <v-btn icon color="grey" x-small v-on:click="setDay('b')">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="selectedteams"
            :items="listteams"
            multiple
            chips
          ></v-select>
        </v-col>
        <v-col cols="1">
          <v-btn color="primary lighten-1" text small @click="updateSchedule()"
            >Update</v-btn
          >
        </v-col>
        <v-col cols="1">
          <span style="text-justify: right">
            <v-switch v-model="do_all" label="Show All"></v-switch>
          </span>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="1">
          <v-chip class="ma-2 float-right" color="primary lighten-2">0%</v-chip>
        </v-col>
        <v-col cols="7">
          <v-progress-linear
            rounded
            stream
            buffer-value="0"
            :active="show_linear"
            :value="valueProgress"
          ></v-progress-linear>
        </v-col>
        <v-col cols="1">
          <v-chip class="ma-2" color="primary lighten-2" :disabled="notdone"
            >100%</v-chip
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>&nbsp;</v-col>
      </v-row>
      <v-row justify="center">
        <v-data-table
          :headers="headers"
          :items="schdata"
          :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, -1] }"
          :items-per-page="50"
          dense
          class="elevation-1"
        >
          <template v-slot:item.person="{ item }">
            <span v-if="item.person == ' Totals'">
              <v-chip small color="secondary">{{ item.person }}</v-chip>
            </span>
            <span v-if="item.person != ' Totals'">{{ item.person }}</span>
          </template>
        </v-data-table>
      </v-row>
    </v-container>
    <v-card v-if="errmsg != ''" max-width="1000" class="mx-auto">
      <v-card-text class="errtext">
        <v-container>
          <v-row>
            {{ errmsg }}
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<style>
.errtext {
  color: "black lighten-1";
  font-family: "Courier New", Courier, monospace;
}
</style>

<script>
import axios from "axios";

export default {
  name: "Calendar",
  props: {
    teams: String,
    qday: String,
  },
  data() {
    return {
      initial_load: true,
      items: [
        { title: "Out of Office", icon: "mdi-network-off", to: "" },
        { title: "Coverage Sched", icon: "mdi-calendar-clock", to: "" },
      ],
      email: "",
      years: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      days: [],
      pickmonth: "",
      pickyear: "",
      pickday: "",
      userinfo: "",
      firstName: "",
      lastName: "",
      imgpic: "",
      teaminfo: "",
      listteams: [],
      selectedteams: [],
      headers: [],
      schdata: [],
      oneschdata: [],
      do_all: true,
      in_process: false,
      show_loading: true,
      show_linear: true,
      valueProgress: 0,
      valueTotal: 0,
      totalRow: 1,
      notdone: true,
      alldone: 0,
      errmsg: "",
    };
  },
  computed: {},
  watch: {
    pickmonth: function () {
      this.setAllDays(this.pickyear, this.pickmonth);
      if (this.duplicateCall()) {
        return;
      }
      this.getSchedules();
    },
    pickyear: function () {
      this.setAllDays(this.pickyear, this.pickmonth);
      if (this.duplicateCall()) {
        return;
      }
      this.getSchedules();
    },
    pickday: function () {
      if (this.duplicateCall()) {
        return;
      }
      this.getSchedules();
    },
    do_all: function () {
      if (this.initial_load) {
        return;
      }
      this.getSchedules();
    },
  },
  methods: {
    duplicateCall() {
      if (this.initial_load || this.in_process) {
        return true;
      }
      return false;
    },
    updateSchedule() {
      this.errmsg = "";
      this.schdata = [];
      this.getSchedules();
    },
    setDay(value) {
      if (value != "f" && value != "b") {
        return;
      }
      let prevnum = "";
      let donext = false;
      for (let i = 0; i < this.days.length; i++) {
        if (donext) {
          this.pickday = this.days[i];
          break;
        }
        if (this.days[i] == this.pickday) {
          // Current selection from descending day list
          if (value == "f") {
            if (i == 0) {
              return;
            }
            this.pickday = prevnum;
            break;
          }
          if (value == "b") {
            if (i + 1 == this.days.length) {
              return;
            }
            donext = true;
          }
        }
        prevnum = this.days[i];
      }
      this.getSchedules();
    },
    getSchedules() {
      if (this.selectedteams.length < 1) {
        this.notdone = false;
        this.show_loading = false;
        this.valueProgress = 100;
        this.in_process = false;
        return;
      }
      this.in_process = true;
      this.valueProgress = 0;
      this.show_loading = true;

      let tmpDate = new Date();
      let currentTimeZoneOffset = tmpDate.getTimezoneOffset();
      let TZname = " " + Intl.DateTimeFormat().resolvedOptions().timeZone;
      TZname = TZname.replace("New_York", "Boston");
      let tmpdict = { person: TZname };
      for (let x = 0; x < 24; x++) {
        let y = x * 60 - currentTimeZoneOffset;
        if (y < 0) {
          y = y + 1440;
        }
        if (y >= 1440) {
          y = y - 1440;
        }
        y = Math.round(y / 6) / 10;
        tmpdict["cov-" + x.toString()] = y;
      }

      let tmpdict1 = { person: " Totals" };
      for (let x = 0; x < 24; x++) {
        tmpdict1["cov-" + x.toString()] = 0;
      }

      this.schdata = [tmpdict, tmpdict1];

      this.alldone = 0;

      for (let j = 0; j < this.selectedteams.length; j++) {
        let tname = this.selectedteams[j];
        if (tname == "") {
          this.notdone = false;
          this.show_loading = false;
          this.valueProgress = 100;
          this.in_process = false;
        } else {
          const path =
            "https://timetracker.mail.ibm.com/api/singleteam?tname=" +
            tname;

          axios
            .get(path)
            .then((res) => {
              try {
                this.teaminfo = res.data.teaminfo;
                let schedulelist_str = this.teaminfo.schedulelists;
                if (schedulelist_str) {
                  let schedulelist = schedulelist_str.split(/[ ,;\t\n]+/);
                  for (let i = 0; i < schedulelist.length; i++) {
                    this.getOneSchedule(schedulelist[i], tname, false);
                  }
                } else {
                  // Record progress
                  this.alldone = this.alldone + 1;
                  this.valueProgress =
                    100 * (this.alldone / this.selectedteams.length);
                  if (this.alldone == this.selectedteams.length) {
                    this.notdone = false;
                    this.show_loading = false;
                    this.in_process = false;
                  }
                }
              } catch (err) {
                this.notdone = false;
                this.show_loading = false;
                this.valueProgress = 100;
                this.in_process = false;
                //if (err.name != "TypeError") {
                console.log(err);
                //}
              }
            })
            .catch((error) => {
              // eslint-disable-next-line
              console.log(error);
              this.notdone = false;
              this.show_loading = false;
              this.valueProgress = 100;
              this.in_process = false;
            });
        }
      }
    },
    getOneSchedule(sched, teamname, onlyone) {
      const path =
        "https://timetracker.mail.ibm.com/api/pd?month=" +
        this.pickmonth +
        "&year=" +
        this.pickyear +
        "&day=" +
        this.pickday +
        "&all=" +
        this.do_all.toString() +
        "&teamname=" +
        teamname +
        "&sched=" +
        sched;
      axios
        .get(path)
        .then((res) => {
          let tmpstatus = res.data.status;
          if (tmpstatus == "success") {
            this.email = res.data.email;
            if (onlyone) {
              this.schdata = res.data.schdata;
            } else {
              let onesched = res.data.schdata;
              for (let i = 0; i < onesched.length; i++) {
                if (onesched[i].person != "") {
                  let needtoadd = true;
                  this.valueTotal = this.schdata.length;
                  for (let j = 1; j < this.schdata.length; j++) {
                    if (
                      this.schdata[j].person.toLowerCase() ==
                      onesched[i].person.toLowerCase()
                    ) {
                      needtoadd = false;
                      for (let k = 0; k < 24; k++) {
                        if ("cov-" + k.toString() in onesched[i]) {
                          // Check if it exists to prevent counting twice
                          if (!this.schdata[j]["cov-" + k.toString()]) {
                            this.schdata[j]["cov-" + k.toString()] =
                              onesched[i]["cov-" + k.toString()];
                            this.schdata[this.totalRow]["cov-" + k.toString()] =
                              this.schdata[this.totalRow][
                                "cov-" + k.toString()
                              ] + 1;
                          }
                        }
                      }
                    }
                  }
                  if (needtoadd) {
                    this.schdata.push(onesched[i]);
                    for (let k = 0; k < 24; k++) {
                      if ("cov-" + k.toString() in onesched[i]) {
                        this.schdata[this.totalRow]["cov-" + k.toString()] =
                          this.schdata[this.totalRow]["cov-" + k.toString()] +
                          1;
                      }
                    }
                  }
                }
              }
              this.schdata.sort(function (a, b) {
                var x = a.person.toLowerCase();
                var y = b.person.toLowerCase();
                if (x < y) {
                  return -1;
                }
                if (x > y) {
                  return 1;
                }
                return 0;
              });
            }
          } else {
            this.errmsg =
              this.errmsg +
              "Error getting " +
              sched +
              " from " +
              teamname +
              ". ";
          }
          // Record progress
          this.alldone = this.alldone + 1;
          this.valueProgress = 100 * (this.alldone / this.selectedteams.length);
          if (this.alldone == this.selectedteams.length) {
            this.notdone = false;
            this.show_loading = false;
            this.in_process = false;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getUserInfo() {
      const path = "https://timetracker.mail.ibm.com/api/userinfo";

      axios
        .get(path)
        .then((res) => {
          this.userinfo = res.data.userinfo;
          this.firstName = this.userinfo.firstName;
          this.lastName = this.userinfo.lastName;
          this.imgpic = res.data.imgpic;
          this.listteams = res.data.listteams;
          if (this.qday) {
            this.pickday = parseInt(this.qday, 10);
          }
          this.initial_load = false;
          if (this.teams) {
            this.selectedteams = this.teams.split(/[,;\t\n]+/);
          } else {
            this.selectedteams = this.userinfo.notifyteams;
          }
          this.getSchedules();
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    authAPI() {
      const path = "https://timetracker.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then((res) => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href =
              "https://timetracker.mail.ibm.com/api/login";
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    setAllDays(year, month) {
      const monthint = this.months.indexOf(month) + 1;
      const daymax = new Date(year, monthint, 0).getDate();
      this.days = [];
      for (let i = 1; i <= daymax; i++) {
        this.days.push(i);
      }
      if (this.pickday > daymax) {
        this.pickday = 1;
      }
    },
  },
  created() {
    this.authAPI();
    const today = new Date();
    this.pickmonth = this.months[today.getMonth()];
    this.pickyear = today.getFullYear();
    this.setAllDays(this.pickyear, this.pickmonth);
    this.pickday = today.getDate();
    const istart = this.pickyear;
    const iend = istart - 10;
    for (let i = istart; i > iend; i--) {
      this.years.push(i);
    }
    this.headers = [{ text: "Name", value: "person", align: "start" }];
    for (let x = 0; x < 24; x++) {
      this.headers.push({
        text: x.toString(),
        value: "cov-" + x.toString(),
        sortable: false,
      });
    }
    this.getUserInfo();
  },
};
</script>