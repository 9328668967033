<template>
  <div id="app" style="padding: 15px">
      <v-toolbar color="tertiary" dark>
        <v-toolbar-title>Entries</v-toolbar-title>
      </v-toolbar>
      <v-container fluid class="grey lighten-5">
        <p>To create a new Time Tracker entry, click the <strong>New</strong> button at the top of the Calendar, fill in the pop-up form, and click Save.</p>
        <v-img
          alt="IBM TT New Entry"
          class="shrink mx-5"
          contain
          src="/tt_new.png"
          transition="scale-transition"
          width="1087"
        />
        <br>
        <p>To edit a Time Tracker entry, click the desired ribbon listing from the Calendar view then click the <strong>Pencil</strong> button.  You can edit the entry as needed then click Save when you're done.</p>
        <v-img
          alt="IBM TT Edit Entry"
          class="shrink mx-5"
          contain
          src="/tt_ribbon.png"
          transition="scale-transition"
          width="283"
        />
        <v-img
          alt="IBM TT Edit Entry"
          class="shrink mx-5"
          contain
          src="/tt_edit.png"
          transition="scale-transition"
          width="527"
        />
        <p>You can delete an entry by clicking Delete instead of Save when you are editing it.</p>
        <p>You can view a list of your personal Time Tracker entries as well as remaining days by selecting <strong>Out of Office</strong> from the main icon in the top left corner.</p>
      </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Help_Entries",
  data() {
    return {
      email: "",
      show_err: false
    };
  },
  computed: {},
  methods: {
    authAPI() {
      const path = "https://timetracker.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then(res => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href =
              "https://timetracker.mail.ibm.com/api/login";
          } else {
            this.email = res.data.email;
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    }
  },
  created() {
    this.authAPI();
  }
};
</script>
