<template>
  <div id="app" style="padding: 15px">
      <v-toolbar color="tertiary" dark>
        <v-toolbar-title>Overview</v-toolbar-title>
      </v-toolbar>
      <v-container fluid class="grey lighten-5">
        <p>Time Tracker is an application intended for employees to track their time off and have it be visible to their team members and other individuals specified.</p>
        <p>The application is available via this website and also through Slack commands and forms.  In addition, schedules from PagerDuty can be listed within teams to allow calendar comparisons.</p>
        <p>To navigate the site, click the icon in the top left corner and select the area to which you wish to navigate.
        <v-img
          alt="IBM TT Navigation"
          class="shrink mx-5"
          contain
          src="/tt_nav.png"
          transition="scale-transition"
          width="428"
        />
        <v-img
          alt="IBM TT Navigation"
          class="shrink mx-5"
          contain
          src="/tt_navoptions.png"
          transition="scale-transition"
          width="245"
        />
        <br>
        <p>Whenever multiple values are permitted, you can separate them with commas, semicolons, or new lines.</p>
        <p>Feedback is always welcome via Slack or email: <a href="mailto:ggr@us.ibm.com">Greg Rick</a></p>
      </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Help_Overview",
  data() {
    return {
      email: "",
      show_err: false
    };
  },
  computed: {},
  methods: {
    authAPI() {
      const path = "https://timetracker.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then(res => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href =
              "https://timetracker.mail.ibm.com/api/login";
          } else {
            this.email = res.data.email;
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    }
  },
  created() {
    this.authAPI();
  }
};
</script>
