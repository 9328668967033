<template>
  <div id="app">
    <v-overlay :value="permitLock && (doLoad || loadHolidays || loadTimeOff)">
      <v-progress-circular :size="50" indeterminate></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dodiag" persistent max-width="800px" @keydown.esc="safeClose">
      <v-card>
        <v-toolbar :color="selectedEvent.color" dark>
          <v-card-title>Editing Entry</v-card-title>
          <v-alert :value="alert" color="error" dark border="top" icon="mdi-alert-circle-outline" transition="scale-transition"
            >You have unsaved changes. Either click Save, or else hit ESC or click Close again to discard changes.</v-alert
          >
          <v-alert :value="savefail" color="error" dark border="top" icon="mdi-alert-circle-outline" transition="scale-transition"
            >Unable to save: {{ savemsg }}</v-alert
          >
        </v-toolbar>
        <v-card-text>
          <v-container class="grey lighten-5">
            <v-row no-gutters>
              <v-col>
                <v-menu
                  ref="smenu"
                  v-model="smenu"
                  :close-on-content-click="false"
                  :return-value.sync="start"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="start" label="Start Date" prepend-icon="mdi-calendar-plus" readonly v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="start" no-title scrollable @input="$refs.smenu.save(start)"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  ref="emenu"
                  v-model="emenu"
                  :close-on-content-click="false"
                  :return-value.sync="end"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="end" label="End Date" prepend-icon="mdi-calendar-plus" readonly v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="end" no-title scrollable @input="$refs.emenu.save(end)"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-switch v-model="half_day" label="Half Day"></v-switch>
            </v-row>
          </v-container>
          <v-container v-if="half_day" class="grey lighten-5">
            <v-row no-gutters>
              <v-col>
                <v-menu
                  ref="stmenu"
                  v-model="tmenu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="starttime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="starttime"
                      label="Start Time (not working)"
                      prepend-icon="mdi-clock-outline"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-if="tmenu1" v-model="starttime" full-width @click:minute="$refs.stmenu.save(starttime)"></v-time-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  ref="etmenu"
                  v-model="tmenu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="endtime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endtime"
                      label="End Time (back to work)"
                      prepend-icon="mdi-clock-outline"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-if="tmenu2" v-model="endtime" full-width @click:minute="$refs.etmenu.save(endtime)"></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row no-gutters align="center">
              <v-col cols="2" align="center">
                <v-chip v-if="etype == 'Vacation' || etype == 'PC Day'" align="" color="secondary">{{ compdays }}</v-chip>
                <v-chip v-if="etype != 'Vacation' && etype != 'PC Day'" align="center" color="primary">{{ compdays }}</v-chip>
              </v-col>
              <v-col cols="10">
                <v-select v-model="etype" :items="names" chips></v-select>
              </v-col>
            </v-row>
          </v-container>
          <v-text-field v-model="details" label="Details"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="secondary" :disabled="disableEdit()" @click="saveChanges">Save</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" :disabled="disableEdit()" @click="delEntry">Delete</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="safeClose">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container v-show="fullshow" fluid>
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat color="white">
              <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Today</v-btn>
              <v-spacer></v-spacer>
              <v-menu v-model="newmenu" :close-on-content-click="false" :close-on-click="false" offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon color="secondary lighten-2" v-on="on">
                    <v-icon>mdi-calendar-plus</v-icon>
                  </v-btn>
                </template>
                <v-card color="grey lighten-4" min-width="350px" flat>
                  <v-toolbar color="secondary" dark>
                    <v-toolbar-title>New Entry</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon>
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-container class="grey lighten-5">
                      <v-row no-gutters>
                        <v-col>
                          <v-menu
                            ref="new_smenu"
                            v-model="new_smenu"
                            :close-on-content-click="false"
                            :return-value.sync="startdate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="startdate"
                                label="Start Date"
                                prepend-icon="mdi-calendar-plus"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="startdate" no-title scrollable @input="$refs.new_smenu.save(startdate)"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col>
                          <v-menu
                            ref="new_emenu"
                            v-model="new_emenu"
                            :close-on-content-click="false"
                            :return-value.sync="enddate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="enddate"
                                label="End Date"
                                prepend-icon="mdi-calendar-plus"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="enddate"
                              :min="startdate"
                              no-title
                              scrollable
                              @input="$refs.new_emenu.save(enddate)"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-switch v-model="half_day" label="Half Day"></v-switch>
                      </v-row>
                    </v-container>
                    <v-container v-if="half_day" class="grey lighten-5">
                      <v-row no-gutters>
                        <v-col>
                          <v-menu
                            ref="new_stmenu"
                            v-model="new_tmenu1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="starttime"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="starttime"
                                label="Start Time (not working)"
                                prepend-icon="mdi-clock-outline"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="new_tmenu1"
                              v-model="starttime"
                              :max="endtime"
                              full-width
                              @click:minute="$refs.new_stmenu.save(starttime)"
                            ></v-time-picker>
                          </v-menu>
                        </v-col>
                        <v-col>
                          <v-menu
                            ref="new_etmenu"
                            v-model="new_tmenu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="endtime"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="endtime"
                                label="End Time (back to work)"
                                prepend-icon="mdi-clock-outline"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="new_tmenu2"
                              v-model="endtime"
                              :min="starttime"
                              full-width
                              @click:minute="$refs.new_etmenu.save(endtime)"
                            ></v-time-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container>
                      <v-row no-gutters align="center">
                        <v-col cols="2" align="center">
                          <v-chip v-if="etype == 'Vacation' || etype == 'PC Day'" align="center" color="secondary">{{ compdays }}</v-chip>
                          <v-chip v-if="etype != 'Vacation' && etype != 'PC Day'" align="center" color="primary">{{ compdays }}</v-chip>
                        </v-col>
                        <v-col cols="10">
                          <v-select v-model="etype" :items="names" chips></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-text-field v-model="details" label="Details"></v-text-field>
                    <v-text-field v-if="savemsg" v-model="savemsg" readonly></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text color="secondary" @click="saveNew()">Save</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="secondary" @click="newmenu = false">Cancel</v-btn>
                  </v-card-actions>
                </v-card> </v-menu
              >New
              <v-spacer></v-spacer>
              <v-switch v-model="show_guest" label="Show Guests"></v-switch>
              <v-spacer></v-spacer>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
              <v-menu bottom right>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="grey darken-2" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 days</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary lighten-1"
              :events="events"
              :event-color="getEventColor"
              :now="today"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateRange"
            ></v-calendar>
            <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement">
              <v-card color="grey lighten-4" min-width="350px" max-width="800px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-btn icon @click="openDialog(selectedEvent)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Calendar",
  data() {
    return {
      email: "",
      focus: "",
      permitLock: true,
      doLoad: false,
      fullshow: false,
      loadHolidays: false,
      loadTimeOff: false,
      show_new: false,
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      start: null,
      end: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      etype: "Vacation",
      details: "",
      newmenu: false,
      smenu: false,
      emenu: false,
      new_smenu: false,
      new_emenu: false,
      startdate: new Date().toISOString().substr(0, 10),
      starttime: null,
      enddate: new Date().toISOString().substr(0, 10),
      endtime: null,
      tmenu1: false,
      tmenu2: false,
      new_tmenu1: false,
      new_tmenu2: false,
      half_day: false,
      dodiag: false,
      alert: false,
      show_guest: true,
      uid: "",
      show_save: false,
      savefail: false,
      savemsg: "",
      events: [],
      colors: ["etype_0", "etype_1", "etype_2", "etype_3", "etype_4", "etype_5"],
      names: ["PC Day", "Vacation", "Appointment", "Compensation", "Sick", "Training"],
      person: "",
      myholidays: [],
    };
  },
  computed: {
    compdays: function () {
      let parts1 = "";
      let parts2 = "";
      if (this.dodiag) {
        parts1 = this.start.match(/(\d+)/g);
        parts2 = this.end.match(/(\d+)/g);
      } else {
        parts1 = this.startdate.match(/(\d+)/g);
        parts2 = this.enddate.match(/(\d+)/g);
      }
      let startDate = new Date(parts1[0], parts1[1] - 1, parts1[2]);
      let endDate = new Date(parts2[0], parts2[1] - 1, parts2[2]);
      // Validate input
      if (endDate < startDate) {
        return 0;
      }

      // Calculate days between dates
      let millisecondsPerDay = 86400 * 1000; // Day in milliseconds
      startDate.setHours(0, 0, 0, 1); // Start just after midnight
      endDate.setHours(23, 59, 59, 999); // End just before midnight
      let diff = endDate - startDate; // Milliseconds between datetime objects
      let days = Math.ceil(diff / millisecondsPerDay);

      // Subtract two weekend days for every week in between
      let weeks = Math.floor(days / 7);
      days -= weeks * 2;

      // Handle special cases
      let startDay = startDate.getDay();
      let endDay = endDate.getDay();

      // Remove weekend not previously removed.
      if (startDay - endDay > 1) {
        days -= 2;
      }
      // Remove start day if span starts on Sunday but ends before Saturday
      if (startDay == 0 && endDay != 6) {
        days--;
      }
      // Remove end day if span ends on Saturday but starts after Sunday
      if (endDay == 6 && startDay != 0) {
        days--;
      }
      /* Here is the code */
      this.myholidays.forEach((day) => {
        let dayparts = day.match(/(\d+)/g);
        let holidayDate = new Date(dayparts[0], dayparts[1] - 1, dayparts[2], 9);
        //console.log("Checking " + holidayDate + " against " + startDate + " and " + endDate)
        if (holidayDate >= startDate && holidayDate <= endDate) {
          //console.log("Passed start/end check")
          /* If it is not saturday (6) or sunday (0), substract it */
          if (holidayDate.getDay() % 6 != 0) {
            //console.log("Passed not weekend")
            days--;
          }
        }
      });
      if (this.half_day) {
        days = days / 2;
      }
      return days;
    },
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  watch: {
    startdate: function () {
      let parts1 = "";
      let parts2 = "";
      if (this.dodiag) {
        parts1 = this.start.match(/(\d+)/g);
        parts2 = this.end.match(/(\d+)/g);
      } else {
        parts1 = this.startdate.match(/(\d+)/g);
        parts2 = this.enddate.match(/(\d+)/g);
      }
      let startDate = new Date(parts1[0], parts1[1] - 1, parts1[2]);
      let endDate = new Date(parts2[0], parts2[1] - 1, parts2[2]);
      // Validate input
      if (endDate < startDate) {
        this.enddate = this.startdate;
      }
    },
    half_day: function () {
      if (!this.half_day) {
        this.start = this.start.substring(0, 10);
        this.end = this.end.substring(0, 10);
        this.starttime = null;
        this.endtime = null;
        this.tmenu1 = false;
        this.tmenu2 = false;
        this.new_tmenu1 = false;
        this.new_tmenu2 = false;
      }
    },
    show_guest: function () {
      this.events = this.getEvents();
    },
  },
  methods: {
    disableEdit() {
      if (this.person == this.email) {
        return false;
      }
      return true;
    },
    openDialog(selEvent) {
      this.uid = selEvent.uid;
      this.start = selEvent.start;
      this.end = selEvent.end;
      this.starttime = selEvent.starttime;
      this.endtime = selEvent.endtime;
      this.half_day = false;
      if (selEvent.halfday == "True") {
        this.half_day = true;
      }
      this.etype = selEvent.etype;
      this.details = selEvent.details;
      this.selectedOpen = false;
      this.person = selEvent.person;
      this.dodiag = true;
    },
    checkDates(sdate, edate) {
      if (sdate == null && edate != null) {
        this.savemsg = "Please enter a start date.";
        return false;
      }
      if (sdate != null && edate == null) {
        this.savemsg = "Please enter an end date.";
        return false;
      }
      if (sdate > edate) {
        this.savemsg = "End date must be the same or later than start date.";
        return false;
      }
      if (edate < sdate) {
        this.savemsg = "End date must be the same or later than start date.";
        return false;
      }
      // Don't allow cross-year to simplify date checking
      if (sdate.substring(0, 4) != edate.substring(0, 4)) {
        this.savemsg = "Dates must be within a single year.  Please split your request in two.";
        return false;
      }
      if (this.half_day) {
        if (this.starttime == null || this.endtime == null) {
          this.savemsg = "Please enter your start and end times for your half-day.";
          return false;
        }
      }
      this.savemsg = "";
      return true;
    },
    saveNew() {
      if (!this.checkDates(this.startdate, this.enddate)) {
        return;
      }
      if (!this.half_day) {
        if (this.startdate.length > 10) {
          this.startdate = this.startdate.substring(0, 10);
        }
        if (this.enddate.length > 10) {
          this.enddate = this.enddate.substring(0, 10);
        }
      }
      // Prepare data 'person', 'startdate', 'starttime', 'enddate', 'endtime', 'etype', 'details'
      let newentry = {
        person: "Anonymous",
        startdate: this.startdate,
        enddate: this.enddate,
        starttime: this.starttime,
        endtime: this.endtime,
        halfday: this.half_day,
        etype: this.etype,
        details: this.details,
        compdays: this.compdays,
      };

      const path = "https://timetracker.mail.ibm.com/api/new";

      axios
        .post(path, newentry)
        .then((res) => {
          this.savemsg = res.data.savemsg;
          if (this.savemsg.startsWith("Saved ")) {
            this.show_save = false;
            this.savefail = false;
            this.safeClose();
            this.newmenu = false;
            this.events = [];
            this.events = this.getEvents();
          } else {
            this.savefail = true;
            this.alert = false;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    saveChanges() {
      if (!this.checkDates(this.start, this.end)) {
        return;
      }
      if (!this.half_day) {
        if (this.start.length > 10) {
          this.start = this.start.substring(0, 10);
        }
        if (this.end.length > 10) {
          this.end = this.end.substring(0, 10);
        }
      }
      // Prepare data 'person', 'startdate', 'starttime', 'enddate', 'endtime', 'etype', 'details'
      let newentry = {
        person: "Anonymous",
        startdate: this.start.substring(0, 10),
        enddate: this.end.substring(0, 10),
        starttime: this.starttime,
        endtime: this.endtime,
        halfday: this.half_day,
        etype: this.etype,
        details: this.details,
        compdays: this.compdays,
        uid: this.uid,
      };

      this.doLoad = true;
      const path = "https://timetracker.mail.ibm.com/api/edit";

      axios
        .post(path, newentry)
        .then((res) => {
          this.savemsg = res.data.savemsg;
          if (this.savemsg.startsWith("Updated ")) {
            this.show_save = false;
            this.savefail = false;
            this.safeClose();
            this.events = [];
            this.events = this.getEvents();
          } else {
            this.savefail = true;
            this.alert = false;
          }
          this.doLoad = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          this.doLoad = false;
        });
    },
    delEntry() {
      let newentry = {
        uid: this.uid,
      };

      const path = "https://timetracker.mail.ibm.com/api/delete";

      axios
        .post(path, newentry)
        .then((res) => {
          this.savemsg = res.data.savemsg;
          if (this.savemsg.startsWith("Deleted ")) {
            this.show_save = false;
            this.savefail = false;
            this.safeClose();
            this.events = [];
            this.events = this.getEvents();
          } else {
            this.savefail = true;
            this.alert = false;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    safeClose() {
      if (this.show_save && !this.alert) {
        this.alert = true;
      } else {
        this.dodiag = false;
        this.alert = false;
        this.uid = "";
        this.savemsg = "";
        this.startdate = new Date().toISOString().substr(0, 10);
        (this.enddate = new Date().toISOString().substr(0, 10)), (this.starttime = null);
        this.endtime = null;
        this.half_day = false;
        this.etype = "Vacation";
        this.details = "";
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.permitLock = false;
      this.$refs.calendar.prev();
    },
    next() {
      this.permitLock = false;
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      if (start.toString().length < 8 || end.toString().length < 8) {
        return;
      }
      this.start = start;
      this.end = end;

      this.events = this.getEvents();
      if (this.events.length == 0) {
        this.authAPI();
      }
    },
    getEvents() {
      this.loadTimeOff = true;
      let fullevents = [];

      const path = "https://timetracker.mail.ibm.com/api/edata?guest=" + this.show_guest;

      axios
        .get(path)
        .then((res) => {
          this.email = res.data.email;
          let evedata = res.data.eventdata;
          for (let i = 0; i < evedata.length; i++) {
            let tmpint = this.names.indexOf(evedata[i].etype);
            if (tmpint < 0) {
              tmpint = 0;
            }
            evedata[i].color = this.colors[tmpint];
            fullevents.push(evedata[i]);
          }
          this.loadTimeOff = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          this.loadTimeOff = false;
        });
      let events = this.getHolidays(fullevents);
      return events;
    },
    getHolidays(addevents) {
      // Now get holidays
      this.loadHolidays = true;
      const path = "https://timetracker.mail.ibm.com/api/holidays";
      axios
        .get(path)
        .then((res) => {
          this.allholidays = res.data.allholidays;
          let tmphol = res.data.myholidays;
          if (typeof tmphol != "undefined" && tmphol != "") {
            if (tmphol.includes(",")) {
              this.myholidays = tmphol.split(",");
            } else {
              this.myholidays = [tmphol];
            }
          } else {
            this.myholidays = [];
          }
          let tmpint = this.names.indexOf("Holiday");
          if (tmpint < 0) {
            tmpint = 0;
          }
          for (let i = 0; i < this.allholidays.length; i++) {
            let tmparr = this.allholidays[i]["holidates"].split(",");
            for (let j = 0; j < tmparr.length; j++) {
              let evedata = {
                name: this.allholidays[i]["name"],
                etype: "Holiday",
                color: this.colors[5],
                start: tmparr[j],
                end: tmparr[j],
              };
              addevents.push(evedata);
            }
          }
          this.loadHolidays = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          this.loadHolidays = false;
        });
      return addevents;
    },
    nth(d) {
      return d > 3 && d < 21 ? "th" : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
    authAPI() {
      this.doLoad = true;
      const path = "https://timetracker.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then((res) => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href = "https://timetracker.mail.ibm.com/api/login";
          } else {
            console.log('Enabling')
            this.fullshow = true;
          }
          this.doLoad = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          this.doLoad = false;
        });
    },
  },
  created() {
    console.log('Checking')
    this.authAPI();
  },
};
</script>
