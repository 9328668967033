<template>
  <div class="view">
    <span v-if="show_sch">
      <Schedule :teams=teams :qday=qday />
    </span>
    <span v-if="show_ooo">
      <OutofOffice />
    </span>
  </div>
</template>

<script>
// @ is an alias to /src
import Schedule from "@/components/Schedule.vue";
import OutofOffice from "@/components/OutofOffice.vue"; 

export default {
  beforeRouteEnter(to, from, next) {
    //Check if there is a query
    if (Object.keys(to.query).length !== 0) {
      next(vm => {
        vm.teams = to.query.teams;
        vm.qday = to.query.qday;
      });
    }
    next();
  },
  name: "View",
  components: {
    Schedule,
    OutofOffice
  },
  data() {
    return {
      comp: "",
      teams: "",
      qday: "",
      show_sch: true,
      show_ooo: false
    };
  },
  watch: {
    "$route.params.comp"(value) {
      this.comp = value;
      if (this.comp == "schedule") {
        this.doSwitch("sch");
      } else {
        this.doSwitch("OoO");
      }
    }
  },
  methods: {
    doSwitch(switchval) {
      if (switchval == "OoO") {
        this.show_sch = false;
        this.show_ooo = true;
        return;
      }
      this.show_sch = true;
      this.show_ooo = false;
    }
  },
  created() {
    this.comp = this.$route.params.comp;
    if (this.comp == "schedule") {
      this.doSwitch("sch");
    } else {
      this.doSwitch("OoO");
    }
  }
};
</script>
