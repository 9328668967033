import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
   theme: {
      themes: {
         light: {
            primary: '#4d2f1b',
            secondary: '#11312b',
            tertiary: '291433',
            error: '#512030',
            etype_0: '#009d92',
            etype_1: '#1826b0',
            etype_2: '#4b4d1b',
            etype_3: '#ffba00',
            etype_4: '#e40045',
            etype_5: '#ff6f00'
         },
      },
   },
});
