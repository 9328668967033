<template>
  <div id="app">
    <v-container fluid px-10 py-3>
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col cols="2">
            <v-select v-model="pickyear" :items="years" chips></v-select>
          </v-col>
          <v-col cols="2">
            <v-select v-model="pickmonth" :items="months" chips></v-select>
          </v-col>
          <v-col cols="5">
            <v-select
              v-model="selectedteams"
              :items="listteams"
              multiple
              chips
            ></v-select>
          </v-col>
          <v-col cols="1">
            <v-btn color="primary lighten-1" text small @click="updateList()"
              >Update</v-btn
            >
          </v-col>
          <v-col cols="2">
            <v-spacer></v-spacer>
            <span style="text-justify: right">
              <v-switch v-model="do_all" label="Show All"></v-switch>
            </span>
          </v-col>
        </v-row>
        <v-row py-3 class="secondary darken-1">
          <span class="white--text text--darken-1 px-2">Out of Office</span>
        </v-row>
        <v-row>
          <v-data-table
            :headers="headers"
            :items="allvacs"
            :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, -1] }"
            :items-per-page="50"
            dense
            class="elevation-1"
          >
          </v-data-table>
        </v-row>
      </v-container>
      <v-container fluid px-1 pt-10>
        <v-row>
          <v-divider></v-divider>
        </v-row>
      </v-container>
      <v-container fluid px-0 pt-1>
        <v-row dense>
          <v-col cols="2">
            <v-select dense solo v-model="lastyear" :items="yesno"></v-select>
          </v-col>
          <v-col cols="10">
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-container fluid px-1 py-1>
              <v-row v-if="!firstName" dense px-1 class="secondary darken-1">
                <v-col>
                  <span class="white--text text--darken-1 px-2"
                    >Personal Time {{ totaldays(this.listentries, 1) }}</span
                  >
                </v-col>
              </v-row>
              <v-row v-if="firstName" dense px-1 class="secondary darken-1">
                <v-col>
                  <span class="white--text text--darken-1 px-2"
                    >Personal Time for {{ firstName }} {{ lastName }}
                    {{ totaldays(this.listentries, 1) }}</span
                  >
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-data-table
                    :headers="listheaders"
                    :items="listentries"
                    :footer-props="{
                      itemsPerPageOptions: [10, 25, 50, 100, -1],
                    }"
                    :items-per-page="50"
                    dense
                    class="elevation-1"
                  >
                    <template v-slot:item.startdate="{ item }">{{
                      niceDate(item.startdate)
                    }}</template>
                    <template v-slot:item.enddate="{ item }">{{
                      niceDate(item.enddate)
                    }}</template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col>
            <v-container fluid pt-1>
              <v-row dense class="secondary darken-1">
                <v-col>
                  <span
                    v-if="otherperson"
                    class="white--text text--darken-1 px-2"
                    >Time for {{ othernicename }}
                    {{ totaldays(this.listotherentries, 0) }}</span
                  >
                  <span
                    v-if!="otherperson"
                    class="white--text text--darken-1 px-2"
                    >Time</span
                  >
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-select
                    v-model="otherperson"
                    :items="allemails"
                    clearable
                    dense
                    solo
                    label="Select an email address"
                  ></v-select
                ></v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-data-table
                    :headers="listheaders"
                    :items="listotherentries"
                    :footer-props="{
                      itemsPerPageOptions: [10, 25, 50, 100, -1],
                    }"
                    :items-per-page="50"
                    dense
                    class="elevation-1"
                  >
                    <template v-slot:item.startdate="{ item }">{{
                      niceDate(item.startdate)
                    }}</template>
                    <template v-slot:item.enddate="{ item }">{{
                      niceDate(item.enddate)
                    }}</template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Calendar",
  data() {
    return {
      items: [
        { title: "Out of Office", icon: "mdi-network-off", to: "" },
        { title: "Coverage Sched", icon: "mdi-calendar-clock", to: "" },
      ],
      email: "",
      years: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      pickmonth: "",
      pickyear: "",
      userinfo: "",
      firstName: "",
      lastName: "",
      imgpic: "",
      listteams: [],
      selectedteams: [],
      vacadays: "",
      pchdays: "",
      headers: [],
      allvacs: [],
      do_all: false,
      listheaders: [
        { text: "Type", value: "etype", align: "start" },
        { text: "Days", value: "compdays" },
        { text: "Start", value: "startdate" },
        { text: "End", value: "enddate" },
      ],
      listentries: [],
      listotherentries: [],
      otherperson: "",
      othernicename: "",
      yesno: [
        { text: "Last year", value: "yes" },
        { text: "Current Year", value: "no" },
      ],
      lastyear: "no",
      allemails: [],
      alreadygetting: false,
    };
  },
  watch: {
    pickmonth: function () {
      if (this.alreadygetting) {
        return;
      }
      this.getVacations();
    },
    pickyear: function () {
      if (this.alreadygetting) {
        return;
      }
      this.getVacations();
    },
    do_all: function () {
      if (this.alreadygetting) {
        return;
      }
      this.getVacations();
    },
    otherperson: function () {
      if (this.alreadygetting) {
        return;
      }
      this.getPersonalEntries();
    },
    lastyear: function () {
      if (this.alreadygetting) {
        return;
      }
      this.getPersonalEntries();
    },
  },
  methods: {
    totaldays(entstocount, doOutOf) {
      if (entstocount.length < 1) {
        return "No entries";
      }
      let totvacs = 0;
      let totpcs = 0;
      let totother = 0;
      for (let x = 0; x < entstocount.length; x++) {
        let tmpdays = parseFloat(entstocount[x]["compdays"]);
        if (entstocount[x]["etype"] == "Vacation") {
          totvacs = totvacs + tmpdays;
        } else {
          if (entstocount[x]["etype"] == "PC Day") {
            totpcs = totpcs + tmpdays;
          } else {
            totother = totother + tmpdays;
          }
        }
      }
      if (doOutOf == 0) {
        return (
          " (Vacation: " +
          totvacs +
          " | PC Day: " +
          totpcs +
          " | Other: " +
          totother +
          ")"
        );
      }
      return (
        " (Vacation: " +
        totvacs +
        " out of " +
        this.vacadays +
        " | PC Day: " +
        totpcs +
        " out of " +
        this.pchdays +
        " | Other: " +
        totother +
        ")"
      );
    },
    updateList() {
      if (this.alreadygetting) {
        return;
      }
      this.getVacations();
    },
    niceDate(longdate) {
      return longdate.slice(0, -12);
      //const ldate = new Date(Date.UTC(longdate));
      //return ldate.toDateString()
      //const nicemon = this.months[ldate.getMonth()]
      //return `${ldate.getFullYear()}` + ' ' + nicemon + ' ' + `${ldate.getDate()}`;
    },
    getVacations() {
      if (!this.selectedteams) {
        return;
      }
      this.alreadygetting = true;
      const path =
        "https://timetracker.mail.ibm.com/api/vacations?month=" +
        this.pickmonth +
        "&year=" +
        this.pickyear +
        "&teams=" +
        this.selectedteams +
        "&all=" +
        this.do_all.toString();

      axios
        .get(path)
        .then((res) => {
          this.headers = res.data.headers;
          this.allvacs = res.data.allvacs;
          this.email = res.data.email;
          this.allemails = res.data.allemails;
          this.alreadygetting = false;
        })
        .catch((error) => {
          console.error(error);
          this.alreadygetting = false;
        });
    },
    getPersonalEntries() {
      const path =
        "https://timetracker.mail.ibm.com/api/listexist?other=" +
        this.otherperson +
        "&lastyear=" +
        this.lastyear;

      axios
        .get(path)
        .then((res) => {
          this.listentries = res.data.listentries;
          this.listotherentries = res.data.listotherentries;
          this.othernicename = res.data.othernicename;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getUserInfo() {
      const path = "https://timetracker.mail.ibm.com/api/userinfo";

      axios
        .get(path)
        .then((res) => {
          this.userinfo = res.data.userinfo;
          this.firstName = this.userinfo.firstName;
          this.lastName = this.userinfo.lastName;
          this.imgpic = res.data.imgpic;
          this.selectedteams = this.userinfo.notifyteams;
          this.listteams = res.data.listteams;
          this.vacadays = this.userinfo.vacadays;
          this.pchdays = res.data.pchdays;
          // Finish initial setup
          this.getVacations();
          this.getPersonalEntries();
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    authAPI() {
      const path = "https://timetracker.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then((res) => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href =
              "https://timetracker.mail.ibm.com/api/login";
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
  created() {
    this.authAPI();
    // Also calls vacations and personal entries
    this.getUserInfo();
    const today = new Date();
    this.pickmonth = this.months[today.getMonth()];
    this.pickyear = today.getFullYear();
    const istart = this.pickyear;
    const iend = istart - 10;
    for (let i = istart; i > iend; i--) {
      this.years.push(i);
    }
  },
};
</script>