<template>
  <div>
    <v-container py-10 fluid>
      <p class="text-center">No matter how long we allow the clock to run, we cannot find that for which you are looking.</p>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "NotFound",
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>