<template>
  <div id="app" style="padding: 15px">
    <v-card max-width="1000" class="mx-auto">
      <v-toolbar color="tertiary" dark>
        <v-toolbar-title>Holidays</v-toolbar-title>
      </v-toolbar>
      <v-container fluid class="grey lighten-5">
        <v-row dense>
          <v-col>
            <v-text-field v-model="holidayName" label="Holiday Name"></v-text-field>
          </v-col>
          <v-col>
            <v-textarea v-model="holidayOwners" label="Holiday Owners" auto-grow rows="1"></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea v-model="holidayDates" label="Holiday Dates" auto-grow rows="3"></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field v-model="pchdays" type="number" label="# of PC Days"></v-text-field>
          </v-col>
          <v-col v-if="holidayDates!=''">
            <v-btn class="mx-2" fab dark color="secondary lighten-1" @click="countPCH()">
              <v-icon dark>mdi-calculator</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn v-if="uid==''" color="primary lighten-1" text @click="createHoliday()">Create</v-btn>
        <v-btn v-if="uid!=''" color="primary lighten-1" text @click="updateHoliday()">Update</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="uid!=''" text color="primary lighten-1" @click="deleteHoliday()">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="uid!=''" color="primary lighten-1" text @click="clearHoliday()">Clear</v-btn>
        <v-container v-if="savemsg!=''">
          <v-row>
            <v-col>
              <v-card-text color="primary lighten-1">{{ savemsg }}</v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <v-container mt-5>
      <v-data-table
        :headers="headers"
        :items="allholidays"
        :items-per-page="15"
        class="elevation-1"
        @click:row="loadHoliday"
      ></v-data-table>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Holidays",
  data() {
    return {
      email: "",
      show_err: false,
      savemsg: "",
      uid: "",
      pchdays: "",
      holidayinfo: "",
      holidayName: "",
      holidayOwners: "",
      holidayDates: "",
      allholidays: [],
      headers: [
        {
          text: "Holiday Name",
          align: "start",
          value: "name"
        },
        { text: "Holiday Owners", value: "owners" },
        { text: "Holiday Dates", value: "holidates" }
      ]
    };
  },
  computed: {},
  methods: {
    countPCH() {
      let numofholidays = this.holidayDates.split(/[,;\t\n]+/).length
      this.pchdays = 12 - numofholidays
    },
    loadHoliday(value) {
      this.savemsg = "";
      this.uid = value.uid;
      this.holidayName = value.name;
      this.holidayOwners = value.owners;
      this.holidayDates = value.holidates;
      this.pchdays = value.pchdays;
    },
    clearHoliday() {
      this.savemsg = "";
      this.uid = "";
      this.holidayName = "";
      this.holidayOwners = "";
      this.holidayDates = "";
      this.pchdays = "";
    },
    getHolidays() {
      const path = "https://timetracker.mail.ibm.com/api/holidays";

      axios
        .get(path)
        .then(res => {
          this.allholidays = res.data.allholidays;
          this.email = res.data.email;
          if (this.holidayOwners == "") {
            this.holidayOwners = this.email;
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getHolidayInfo() {
      if (this.uid == "") {
        return;
      }
      const path =
        "https://timetracker.mail.ibm.com/api/singleholiday?uid=" +
        this.uid;

      axios
        .get(path)
        .then(res => {
          this.holidayinfo = res.data.holidayinfo;
          this.holidayName = this.holidayinfo.name;
          this.holidayOwners = this.holidayinfo.owners;
          this.holidayDates = this.holidayinfo.holidates;
          this.pchdays = this.holidayinfo.pchdays;
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    createHoliday() {
      const path = "https://timetracker.mail.ibm.com/api/createholiday";

      this.savemsg = "";
      const tmparr_O = this.holidayOwners.trim().split(/[ ,;\t\n]+/);
      const tmparr_M = this.holidayDates.trim().split(/[ ,;\t\n]+/);
      let upentry = {
        name: this.holidayName,
        owners: tmparr_O,
        holidates: tmparr_M,
        pchdays: this.pchdays
      };

      axios
        .post(path, upentry)
        .then(res => {
          const holdmsg = res.data.savemsg;
          if (holdmsg.startsWith("Created ")) {
            this.show_err = false;
            this.holidayinfo = res.data.holidayinfo;
            this.loadHoliday(this.holidayinfo);
            this.getHolidays();
            this.savemsg = holdmsg;
          } else {
            this.show_err = true;
            this.savemsg = holdmsg;
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    updateHoliday() {
      const path = "https://timetracker.mail.ibm.com/api/updateholiday";

      this.savemsg = "";
      const tmparr_O = this.holidayOwners.trim().split(/[ ,;\t\n]+/);
      const tmparr_M = this.holidayDates.trim().split(/[ ,;\t\n]+/);
      let upentry = {
        uid: this.uid,
        name: this.holidayName,
        owners: tmparr_O,
        holidates: tmparr_M,
        pchdays: this.pchdays
      };

      axios
        .post(path, upentry)
        .then(res => {
          const holdmsg = res.data.savemsg;
          if (holdmsg.startsWith("Updated ")) {
            this.show_err = false;
            this.holidayinfo = res.data.holidayinfo;
            this.loadHoliday(this.holidayinfo);
            this.getHolidays();
            this.savemsg = holdmsg;
          } else {
            this.show_err = true;
            this.savemsg = holdmsg;
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    deleteHoliday() {
      if (this.uid == "") {
        return;
      }
      const path =
        "https://timetracker.mail.ibm.com/api/deleteholiday?uid=" +
        this.uid;

      axios
        .get(path)
        .then(res => {
          const holdmsg = res.data.savemsg;
          if (holdmsg.startsWith("Deleted ")) {
            this.show_err = false;
            this.clearHoliday();
            this.getHolidays();
            this.savemsg = holdmsg;
          } else {
            this.show_err = true;
            this.savemsg = holdmsg;
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    authAPI() {
      const path = "https://timetracker.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then(res => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href =
              "https://timetracker.mail.ibm.com/api/login";
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.error(error);
        });
    }
  },
  created() {
    this.authAPI();
    this.getHolidays();
  }
};
</script>
