<template>
  <div class="help">
    <v-container fluid>
      <v-row>
        <v-col cols="2">
          <v-card elevation="12" width="200">
            <v-navigation-drawer floating permanent>
              <v-list dense rounded>
                <v-list-item
                  v-for="item in items"
                  :key="item.title"
                  @click="changeCard(item.switchkey)"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
          </v-card>
        </v-col>
        <v-col cols="8">
          <span v-if="show_overview">
            <Help_Overview />
          </span>
          <span v-if="show_entries">
            <Help_Entries />
          </span>
          <span v-if="show_teams">
            <Help_Teams />
          </span>
          <span v-if="show_slack">
            <Help_Slack />
          </span>
        </v-col>
        <v-col cols="2">&nbsp;</v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Help_Overview from "@/components/Help_Overview.vue";
import Help_Entries from "@/components/Help_Entries.vue";
import Help_Teams from "@/components/Help_Teams.vue";
import Help_Slack from "@/components/Help_Slack.vue";

export default {
  name: "Help",
  components: {
    Help_Overview,
    Help_Entries,
    Help_Teams,
    Help_Slack
  },
  data() {
    return {
      show_overview: true,
      show_entries: false,
      show_teams: false,
      show_slack: false,
      items: [
        { title: "Overview", switchkey: "0", icon: "mdi-home", to: "" },
        { title: "Entries", switchkey: "1", icon: "mdi-calendar-account", to: "" },
        { title: "Teams", switchkey: "2", icon: "mdi-account-group-outline", to: "" },
        { title: "Slack", switchkey: "3", icon: "mdi-slack", to: "" }
      ]
    };
  },
  methods: {
    changeCard(cardname) {
      if (cardname == "1") {
        this.show_entries = true;
        this.show_teams = false;
        this.show_overview = false;
        this.show_slack = false;
        return;
      }
      if (cardname == "2") {
        this.show_teams = true;
        this.show_overview = false;
        this.show_entries = false;
        this.show_slack = false;
        return;
      }
      if (cardname == "3") {
        this.show_slack = true;
        this.show_overview = false;
        this.show_teams = false;
        this.show_entries = false;
        return;
      }
      this.show_overview = true;
      this.show_entries = false;
      this.show_teams = false;
      this.show_slack = false;
    }
  }
};
</script>