<template>
  <div class="config">
    <v-container fluid>
      <v-row>
        <v-col cols="2">
          <v-card elevation="12" width="200">
            <v-navigation-drawer floating permanent>
              <v-list dense rounded>
                <v-list-item
                  v-for="item in items"
                  :key="item.title"
                  @click="changeCard(item.title)"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
          </v-card>
        </v-col>
        <v-col cols="8">
          <span v-if="show_profile">
            <Profile />
          </span>
          <span v-if="show_teams">
            <Teams />
          </span>
          <span v-if="show_holidays">
            <Holidays />
          </span>
        </v-col>
        <v-col cols="2">&nbsp;</v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
// @ is an alias to /src
import Profile from "@/components/Profile.vue";
import Teams from "@/components/Teams.vue";
import Holidays from "@/components/Holidays.vue";

export default {
  name: "Config",
  components: {
    Profile,
    Teams,
    Holidays,
  },
  data() {
    return {
      show_profile: true,
      show_teams: false,
      show_holidays: false,
      items: [
        { title: "Profile", icon: "mdi-account", to: "" },
        { title: "Teams", icon: "mdi-account-group", to: "" },
        { title: "Holidays", icon: "mdi-flower", to: "" },
      ],
    };
  },
  methods: {
    changeCard(cardname) {
      if (cardname == "Teams") {
        this.show_teams = true;
        this.show_profile = false;
        this.show_holidays = false;
        return;
      }
      if (cardname == "Holidays") {
        this.show_holidays = true;
        this.show_profile = false;
        this.show_teams = false;
        return;
      }
      this.show_profile = true;
      this.show_teams = false;
      this.show_holidays = false;
    },
    authAPI() {
      const path = "https://timetracker.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then((res) => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href = "https://timetracker.mail.ibm.com/api/login?ploc=config";
          } else {
            //this.getTimeZones();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
  created() {
    this.authAPI();
  },
};
</script>